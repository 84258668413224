
import Icon from '@root/modules/weather/components/Icon.vue';
import getLocationUrl from '@root/modules/weather/utils/getLocationUrl';
import getUserLocation from '@root/modules/weather/utils/getUserLocation';
import LocationsService from '@root/modules/weather/services/Locations.service';
import FormInput from '@core/components/UI/form/FormInput.vue';

interface Data {
  locationList: { items: { placeId: string; formattedAddress: string }[] } | null;
  location: string;
  showList: boolean;
  gettingUserLocation: boolean;
  userLocationError: string;
  debounce: any;
}

interface Props {
  isWidget: boolean;
}

interface Methods {
  newLocation: (location: any) => void;
  myLocation: () => Promise<any>;
  debounceSearch: (value: string) => void;
}

export default getLocationUrl.extend<Data, Methods, unknown, Props>({
  components: {
    FormInput,
    Icon,
  },
  data() {
    return {
      locationList: null,
      location: '',
      showList: true,
      gettingUserLocation: false,
      userLocationError: '',
      debounce: false,
    };
  },
  watch: {
    async location(value) {
      const locationsService = new LocationsService();
      const data = await locationsService.fetch({ locationName: this.location });

      if (data && data.length > 0) {
        this.locationList = data;
      }

      this.showList = !!value;
    },
  },
  props: {
    isWidget: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    newLocation(newLocation: any) {
      this.location = '';

      const locationUrl = this.getLocationUrl(newLocation, this.$props.isWidget);
      this.$emit('change-location', newLocation);

      if (this.$props.isWidget) {
        window.location.href = locationUrl;
      } else {
        this.$router.push({ path: locationUrl });
      }
    },
    async myLocation() {
      this.gettingUserLocation = true;
      try {
        this.gettingUserLocation = false;
        const { coords } = await getUserLocation();
        const userLocation = { lat: `${coords.latitude}`, lon: `${coords.longitude}` };
        this.$emit('change-location', userLocation);

        if (this.$route.name === 'weather') {
          const locationUrl = this.getLocationUrl({ lat: coords.latitude, lon: coords.longitude }, this.$props.isWidget);
          if (this.$props.isWidget) {
            window.location.href = locationUrl;
          } else {
            this.$router.push({ path: locationUrl });
          }
        }
      } catch (e) {
        this.gettingUserLocation = false;
        this.userLocationError = e.message;
      }
    },
    debounceSearch(value) {
      if (value.length < 3) {
        return;
      }

      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.location = value;
      }, 1500);
    },
  },
});
