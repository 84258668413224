
import Vue from 'vue';
import { Moon } from 'lunarphase-js';

interface Props {
  currentWeather: any;
}

interface Computed {
  weatherData: any;
}

export default Vue.extend<unknown, unknown, Computed, Props>({
  props: {
    currentWeather: {
      type: Object,
      default: null,
    },
  },
  computed: {
    weatherData() {
      if (!this.currentWeather) {
        return false;
      }
      const locationForecast = {
        current: this.currentWeather.currentWeather,
        sunrise: this.currentWeather.sunrise,
        sunset: this.currentWeather.sunset,
      };

      const date = new Date();
      const phase = `weather.moonPhases.${Moon.lunarPhase(date).replace(/ /g, '')}`;
      const phaseSymbol = Moon.lunarPhaseEmoji(date);
      locationForecast.current.moonPhase = `<span>${phaseSymbol}</span> ${this.$t(phase)}`;

      return locationForecast;
    },
  },
});
