
import Vue from 'vue';
import LocationSearch from '@root/modules/weather/components/location/LocationSearch.vue';
import LocationMore from '@root/modules/weather/components/location/LocationMore.vue';
import LocationCurrent from '@root/modules/weather/components/location/LocationCurrent.vue';
import LocationHoursDays from '@root/modules/weather/components/location/LocationHoursDays.vue';
import LocationToday from '@root/modules/weather/components/location/LocationToday.vue';
import { weatherBackgroundParser } from '@root/modules/weather/utils/weatherParser';

interface Methods {
  forwardNewLocation: (forwardNewLocation: any) => void;
  weatherBackgroundParser: typeof weatherBackgroundParser;
}

interface Computed {
  currentWeatherSummary: string;
}

export default Vue.extend<unknown, Methods, Computed, unknown>({
  components: {
    LocationSearch,
    LocationMore,
    LocationCurrent,
    LocationHoursDays,
    LocationToday,
  },
  props: {
    weatherData: {
      type: Object,
      required: false,
      default: null,
    },
    isWidget: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    currentWeatherSummary() {
      return weatherBackgroundParser(
        this.$props.weatherData.currentWeather.data.next_1_hours.summary?.symbol_code || '',
        this.$props.weatherData.currentWeather.time
      );
    },
  },
  methods: {
    forwardNewLocation(forwardNewLocation: any) {
      this.$emit('forward-location', forwardNewLocation);
    },
    weatherBackgroundParser,
  },
});
