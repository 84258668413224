
import Vue from 'vue';
import Icon from '@root/modules/weather/components/Icon.vue';
import { toDailyForecast, toHourlyForecast, toWidgetDailyForecast, weatherIconParser, isSummerTime } from '@root/modules/weather/utils/weatherParser';

interface Props {
  currentWeather: any;
}

interface Methods {
  weatherIconParser: typeof weatherIconParser;
}

export default Vue.extend<unknown, Methods, unknown, Props>({
  components: {
    Icon,
  },
  props: {
    currentWeather: {
      type: Object,
      default: null,
    },
  },
  computed: {
    weatherData() {
      if (!this.currentWeather) {
        return false;
      }
      const locationForecast = {
        current: this.currentWeather.currentWeather,
        daily: toWidgetDailyForecast(toDailyForecast(this.currentWeather.dailyForecast), 3),
        hourly: toHourlyForecast(this.currentWeather.hourlyForecast),
      };

      return locationForecast;
    },
    timeList() {
      if (isSummerTime(new Date())) {
        return ['09:00', '15:00', '21:00'];
      } else {
        return ['08:00', '14:00', '20:00'];
      }
    },
  },
  methods: {
    weatherIconParser,
  },
});
