
import getLocationUrl from '@root/modules/weather/utils/getLocationUrl';

interface Props {
  location: Record<any, any>;
  isWidget: Record<any, any>;
}

interface Methods {
  goToPage: () => void;
}

export default getLocationUrl.extend<unknown, Methods, unknown, Props>({
  props: {
    location: {
      type: Object,
      required: true,
    },
    isWidget: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    goToPage() {
      const locationUrl = this.getLocationUrl(this.location, this.$props.isWidget);

      if (this.$props.isWidget) {
        window.location.href = locationUrl;
      } else {
        this.$router.push({ path: locationUrl });
      }
    },
  },
});
