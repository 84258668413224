import Service from '@root/common/base/Service';
import { ServiceInterface } from '@root/common/types/service';

import { Locations } from '@root/modules/weather/typings/locations';
import getLocations from '@root/modules/weather/graphql/getLocations.graphql';

type ServiceVariables = Locations.FetchLocations['variables'];

export default class LocationsService extends Service implements ServiceInterface {
  public async fetch(variables: ServiceVariables) {
    try {
      const apiProvider = this.createProvider('GraphQL');
      apiProvider.selectAPI('weather-api').setLinkOptions({ useAutomaticPersistedQueries: true, useGETAutomaticPersistedQueries: true });

      const options = Object.assign({ query: getLocations }, { variables });

      const response = await apiProvider.query<Locations.FetchLocations['data']>(options);

      return response.data.locations.items;
    } catch (e) {
      const error = this.generateErrorData(e);
      this.handleError(error);
    }
  }
}
