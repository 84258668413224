
import Vue from 'vue';
import Icon from '@root/modules/weather/components/Icon.vue';
import { toDailyForecast, weatherIconParser } from '@root/modules/weather/utils/weatherParser';

interface Props {
  currentWeather: any;
  isWidget: boolean;
}

interface Computed {
  weatherData: Record<string, any>;
}

interface Methods {
  weatherIconParser: typeof weatherIconParser;
}

export default Vue.extend<unknown, Methods, Computed, Props>({
  components: {
    Icon,
  },
  props: {
    currentWeather: {
      type: Object,
      default: null,
    },
    isWidget: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    weatherData() {
      const locationForecast = {
        current: this.currentWeather.currentWeather,
        location: this.currentWeather.location,
        daily: toDailyForecast(this.currentWeather.dailyForecast),
      };

      return locationForecast;
    },
  },
  methods: {
    weatherIconParser,
  },
});
